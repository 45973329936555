.cinema-card {
  background-color: #f7f7f79e;
  padding: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
}
.cinema-card:hover {
  background-color: #add8e624;
}
.filter{
  display: flex;
  justify-content: space-between;
}
.cinema-card h4 {
  font-size: 18px;
  padding: 4px 0px;
  color: black;

  -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-weight: 500;
    margin-bottom: .6rem;
    overflow: hidden;
}
.person {
  color: #505f79;
  font-size: small;
  margin-bottom: 5px;
}
.image-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.myiamge{
  width: 100%;
  height: 100px;
}
.image {
  aspect-ratio: 6/4;
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

.image-details {
  position: absolute;
  top: -100%; /* Start above the image */
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(253, 0, 0, 0.491);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: top 0.5s ease; /* Transition effect */
}

.image-container:hover .image-details {
  top: 0; /* Move down to cover the image */
}
.clickmore:hover {
  color: white;
  font-weight: 600;
}
.clickmore {
  width: 9em;
  height: 3em;
  border-radius: 30em;
  font-size: 15px;
  font-family: inherit;
  border: none;
  position: relative;
  overflow: hidden;
  z-index: 1;
  box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;
}

.clickmore::before {
  content: "";
  width: 0;
  height: 3em;
  border-radius: 30em;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(
    98.3deg,
    rgb(0, 0, 0) 10.6%,
    rgb(255, 0, 0) 97.7%
  );
  transition: 0.5s ease;
  display: block;
  z-index: -1;
  color: white;
}

.clickmore:hover::before {
  width: 9em;
}
.addbutton{
  display: flex;
  justify-content: center;
}
.cssbuttons-io {
  position: relative;
  font-family: inherit;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.05em;
  border-radius: 2rem;
  cursor: pointer;
  border: none;
  background: linear-gradient(to right, red, black);
  color: ghostwhite;
  overflow: hidden;
}

.cssbuttons-io svg {
  width: 1.2em;
  height: 1.2em;
  margin-right: 0.5em;
}

.cssbuttons-io span {
  position: relative;
  z-index: 10;
  transition: color 0.4s;
  display: inline-flex;
  align-items: center;
  padding: 6px 10px;
  font-size: 14px;
}

.cssbuttons-io::before,
.cssbuttons-io::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.cssbuttons-io::before {
  content: "";
  background: #000;
  width: 120%;
  left: -10%;
  transform: skew(30deg);
  transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
}

.cssbuttons-io:hover::before {
  transform: translate3d(100%, 0, 0);
}

.cssbuttons-io:active {
  transform: scale(0.95);
}
.filteration{
  display: flex;
    justify-content: space-evenly;
    align-items: end;
    gap: 10px;
}
.filteration select{
  border-radius: 20px;
}
.filteration select option:hover{
background-color: red;
}
/* Container for pagination controls */
.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

/* General button styling */
.pagination-btn {
  background-color: #0f1010; /* Blue background */
  color: #fff; /* White text */
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

/* Button hover effect */
.pagination-btn:hover {
  background-color: #0f1010; /* Darker blue */
  transform: scale(1.05);
}

/* Button disabled state */
.pagination-btn:disabled {
  background-color: #e0e0e0; /* Light gray background */
  color: #a0a0a0; /* Gray text */
  cursor: not-allowed;
}

/* Specific styles for previous button */
.pagination-prev {
  margin-right: 10px;
}

/* Specific styles for next button */
.pagination-next {
  margin-left: 10px;
}

/* Page info styling */
.pagination-info {
  font-size: 16px;
  color: #333; /* Dark gray text */
  margin: 0 15px;
}
