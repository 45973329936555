@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: poppins;
}

body {
  background-color: white;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none !important;
}

.para {
  font-size: 1em;
  font-weight: 300;
  font-family: "Poppins";
  letter-spacing: 1px;
  line-height: 40px;
  text-align: justify;
}

.heading {
  font-weight: 700;
  font-size: 35px;
  text-align: center;
  color: white;
}

.our-services {
  padding: 2rem;
  height: 100%;
}

.serverHeading {
  width: 100%;
  text-align: center;
}

.serverHeading span {
  font-size: 26px;
  font-weight: 700;
  position: relative;
  text-align: center;
}

.serverHeading span::before {
  content: "";
  width: 100%;
  height: 30%;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  position: absolute;
  top: 35%;
  left: 120%;
}

.serverHeading span::after {
  content: "";
  width: 100%;
  height: 30%;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  position: absolute;
  top: 35%;
  right: 120%;
}

.our-services .para {
  color: white;
  font-size: 16px;
}

.our-services .icon {
  text-align: center;
  font-size: 40px;
  color: white;
  margin-bottom: 1rem;
}

.our-services:hover {
  background: linear-gradient(98.3deg,
      rgb(0, 0, 0) 10.6%,
      rgb(236 31 39) 97.7%) !important;
  transition: 1s !important;
}

.manage-image {
  aspect-ratio: 1;
}

.modal-dialog {
  max-width: 50% !important;
}

.myselfcssmodal {
  display: "flex";
  background-color: "rgba(0, 0, 0, 0.8)";
  justify-content: center;

}

.mycsssmodal {
  height: 500px;
}

.img-fluid {
  max-width: 100%;
  height: 400px;
}

.allheadings {
  font-size: 22px;
}

.filterButton {
  position: relative;
  display: inline-block;
  margin: 5px;
  padding: 10px 20px;
  text-align: center;
  font-size: 18px;
  letter-spacing: 1px;
  text-decoration: none;
  color: black;
  background: transparent;
  cursor: pointer;
  transition: ease-out 0.5s;
  border: 2px solid black;
  border-radius: 10px;
  box-shadow: inset 0 0 0 0 black;
}

.filterButton:hover {
  color: white;
  box-shadow: inset 0 -100px 0 0 black;
}

.filterButton:active {
  transform: scale(0.9);
}

@media (min-width: 768px) {


  .modal-dialog {
    max-width: 30% !important;
  }
}

@media (max-width: 768px) {
  .allheadings {
    font-size: 18px;
  }
  .filterButton {
    padding: 5px 10px;
  }
  .heading {
    font-size: 26px;
    color: white;
  }

  .our-services .para {
    color: white;
    font-size: 12px;
    line-height: 22px;
    margin-top: 1rem;
  }

  .our-services {
    padding: 1rem;
  }

  .serverHeading span::after {
    height: 20%;
    top: 42%;
    right: 108%;
  }

  .serverHeading span::before {
    height: 20%;
    top: 42%;
    left: 108%;
  }

  .services {
    margin-top: 0;
  }

  .modal.fade.show {
    padding: 2rem 1rem !important;
  }

  .modal-dialog {
    max-width: 100% !important;
  }
}