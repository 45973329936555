/* body {
    margin: 0;
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f4;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
} */

.thank-you-container {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    padding: 20px;
    text-align: center;
    margin:40px auto ;

}

.thank-you-logo {
    max-width: 150px;
    margin-bottom: 20px;
}

.thank-you-container h1 {
    color: #343a40;
    font-size: 24px;
    margin-bottom: 10px;
}

.thank-you-container p {
    color: #6c757d;
    font-size: 16px;
    line-height: 1.5;
}

.thank-you-button {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #343a40;
    color: #ffffff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.thank-you-button:hover {
    background-color: #495057;
    color: white;
}

@media (max-width: 600px) {
    .thank-you-container {
        padding: 15px;
    }
    .thank-you-container h1 {
        font-size: 20px;
    }
    .thank-you-container p {
        font-size: 14px;
    }
}
