.blog-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
}

.blog {
    background-color: #1e1e1e;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    flex: 1 0 calc(33.33% - 20px);
    display: flex;
    flex-direction: column;
}

.blog-image {
    position: relative;
}

.blog-image img {
    width: 100%;
    height: auto;
    transition: transform 0.3s;
}

.blog-image img:hover {
    transform: scale(1.05);
}

.blog-image .date {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 14px;
}

.blog-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.blog-content h2 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #007bff;
}

.blog-content p {
    flex-grow: 1;
    color: #bbb;
    margin-bottom: 20px;
}

.blog-content a {
    align-self: flex-start;
    /* background-color: #007bff; */
    color: #fff;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.blog-content a:hover {
    /* background-color: #0056b3; */
}
@media (max-width: 768px) {
    .blog-row {
      display: block;
    }
    .blog{
        margin-bottom: 2rem;
    }
  }