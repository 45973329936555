.contact {
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: 2rem;
    background-color: #fafafa;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .contact-info{
    background-color: white;
    padding: 2rem;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
  }
  @media only screen and (max-width: 768px) {
    .contact{
      padding: 0px;
    }
  }
  .icon {
    width: 28px;
    margin-right: 0.7rem;
  }
  
  .social-media {
    padding: 2rem 0 0 0;
  }
  
  .social-media p {
    color: #333;
  }
  
  .social-icons {
    display: flex;
    margin-top: 0.5rem;
  }
  
  .social-icons a {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background: black;
    color: #fff;
    text-align: center;
    line-height: 35px;
    margin-right: 0.5rem;
    transition: 0.3s;
  }
  
  .social-icons a:hover {
    transform: scale(1.05);
  }
  
 
.information i {
    color: black;
  }
  
.information {
    display: flex;
    color: #555;
    margin: 0.7rem 0;
    font-size: 0.95rem;
    gap: 20px;
  }

  
.contact-form {
    background-color: black;
    padding: 2rem;
    position: relative;
  }

  
.contact-form:before {
    content: "";
    position: absolute;
    width: 26px;
    height: 26px;
    background-color: black;
    transform: rotate(45deg);
    top: 50px;
    left: -13px;
  }

  .title {
    color: #fff;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1;
    margin-bottom: 0.7rem;
  }
  
  .input-container {
    position: relative;
    margin: 1rem 0;
  }
  
.input {
    width: 100%;
    outline: none;
    border: 2px solid #fafafa;
    background: none;
    padding: 0.6rem 1.2rem;
    color: #fff;
    font-weight: 500;
    font-size: 0.95rem;
    letter-spacing: 0.5px;
    border-radius: 5px;
    transition: 0.3s;
  }
  
  textarea.input {
    padding: 0.8rem 1.2rem;
    min-height: 150px;
    border-radius: 5px;
    resize: none;
    overflow-y: auto;
  }
  
  .input-container label {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    padding: 0 0.4rem;
    color: #fafafa;
    font-size: 0.9rem;
    font-weight: 400;
    pointer-events: none;
    z-index: 1000;
    transition: 0.5s;
  }
  
  .input-container.textarea label {
    top: 1rem;
    transform: translateY(0);
  }
  
  .btn {
    padding: 0.6rem 1.3rem;
    background-color: black;
    border: 2px solid #fafafa;
    font-size: 0.95rem;
    color: white;
    line-height: 1;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    margin: 0;
    width: 100%;
  }
  
  .btn:hover {
    background-color: white;
    color: black;
  }
  
  .input-container span {
    position: absolute;
    top: 0;
    left: 25px;
    transform: translateY(-50%);
    font-size: 0.8rem;
    padding: 0 0.4rem;
    color: transparent;
    pointer-events: none;
    z-index: 500;
  }
  
  .input-container span:before,
  .input-container span:after {
    content: "";
    position: absolute;
    width: 10%;
    opacity: 0;
    transition: 0.3s;
    height: 5px;
    background-color: #1abc9c;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .input-container span:before {
    left: 50%;
  }
  
  .input-container span:after {
    right: 50%;
  }
  
  .input-container.focus label {
    top: 0;
    transform: translateY(-50%);
    left: 25px;
    font-size: 0.8rem;
  }
  
  .input-container.focus span:before,
  .input-container.focus span:after {
    width: 50%;
    opacity: 1;
  }
  
  .contact-info {
    padding: 2.3rem 2.2rem;
    position: relative;
  }
  
  .contact-info .title {
    color: black;
    font-weight: 700;
  }
  
  .text {
    color: #333;
    margin: 1.5rem 0 2rem 0;
  }
