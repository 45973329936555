/* footer{
    padding: 2rem;
}
@media screen and (max-width:600px) {
    footer{
        padding: 1rem;
    }
} */
a {
    text-decoration: none;
}

.pg-footer {
    font-family: 'Roboto', sans-serif;
}

.footer {
    background-color: darkslategray;
    color: #fff;
}

.footer-wave-svg {
    background-color: transparent;
    display: block;
    height: 30px;
    position: relative;
    top: -1px;
    width: 100%;
}

.footer-wave-path {
    fill: #fffff2;
}

.footer-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 450px;
    position: relative;
}

.footer-content-column {
    box-sizing: border-box;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    color: #fff;
}

.footer-content-column ul li a {
    color: #fff;
    text-decoration: none;
}

.footer-logo-link {
    display: inline-block;
}

.footer-menu {
    margin-top: 30px;
}

.footer-menu-name {
    color: #fffff2;
    font-size: 15px;
    font-weight: 900;
    letter-spacing: .1em;
    line-height: 18px;
    margin-bottom: 0;
    margin-top: 0;
    text-transform: uppercase;
}

.footer-menu-list {
    list-style: none;
    margin-bottom: 0;
    margin-top: 10px;
    padding-left: 0;
}

.footer-menu-list li {
    margin-top: 5px;
}

.footer-call-to-action-description {
    color: #fffff2;
    margin-top: 10px;
    margin-bottom: 20px;
}

.footer-call-to-action-button:hover {
    background-color: #fffff2;
    color: #00bef0;
}

.button:last-of-type {
    margin-right: 0;
}

.footer-call-to-action-button {
    background-color: #027b9a;
    border-radius: 21px;
    color: #fffff2;
    display: inline-block;
    font-size: 11px;
    font-weight: 900;
    letter-spacing: .1em;
    line-height: 18px;
    padding: 12px 30px;
    margin: 0 10px 10px 0;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color .2s;
    cursor: pointer;
    position: relative;
}

.footer-call-to-action {
    margin-top: 30px;
}

.footer-call-to-action-title {
    color: #fffff2;
    font-size: 14px;
    font-weight: 900;
    letter-spacing: .1em;
    line-height: 18px;
    margin-bottom: 0;
    margin-top: 0;
    text-transform: uppercase;
}

.footer-call-to-action-link-wrapper {
    margin-bottom: 0;
    margin-top: 10px;
    color: #fff;
    text-decoration: none;
}

.footer-call-to-action-link-wrapper a {
    color: #fff;
    text-decoration: none;
}

.footer-social-links {
    bottom: 0;
    height: 54px;
    position: absolute;
    right: 0;
    width: 236px;
}

.footer-social-amoeba-svg {
    height: 54px;
    left: 0;
    display: block;
    position: absolute;
    top: 0;
    width: 236px;
}

.footer-social-amoeba-path {
    fill: black;
    color: white;
}

.footer-social-link.linkedin {
    height: 45px;
    left: 5px;
    top: 11px;
    width: 45px;
    font-size: 20px;
}

.footer-social-link {
    display: block;
    padding: 10px;
    position: absolute;
    color: white;
}

.hidden-link-text {
    position: absolute;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
    clip-path: inset(0px 0px 99.9% 99.9%);
    overflow: hidden;
    height: 1px;
    width: 1px;
    padding: 0;
    border: 0;
    top: 50%;
}

.footer-social-icon-svg {
    display: block;
}

.footer-social-icon-path {
    fill: #fffff2;
    transition: fill .2s;
}

.footer-social-link.twitter {
    left: 60px;
    top: -3px;
    font-size: 30px;
}

.footer-social-link.youtube {
    left: 125px;
    top: 10px;
    font-size: 20px;
}

.footer-social-link.github {
    top: 7px;
    left: 178px;
    top: 10px;
    font-size: 20px;
}

.footer-copyright {
    background-color: black;
    color: #fff;
    padding: 15px 30px;
    text-align: center;
}

.footer-copyright-wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
}

.footer-copyright-text {
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 0;
    margin-top: 0;
}

.footer-copyright-link {
    color: #fff;
    text-decoration: none;
}







/* Media Query For different screens */
@media (min-width:320px) and (max-width:479px) {

    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
    .footer-content {
        margin-left: auto;
        margin-right: auto;
        max-width: 1230px;
        padding: 40px 15px 60px;
        position: relative;
    }
}

@media (min-width:480px) and (max-width:599px) {

    /* smartphones, Android phones, landscape iPhone */
    .footer-content {
        margin-left: auto;
        margin-right: auto;
        max-width: 1230px;
        padding: 40px 15px 60px;
        position: relative;
    }
}

@media (min-width:600px) and (max-width: 800px) {

    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    .footer-content {
        margin-left: auto;
        margin-right: auto;
        max-width: 1230px;
        padding: 40px 15px 70px;
        position: relative;
    }
}

@media (min-width:801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */

}

@media (min-width:1025px) {
    /* big landscape tablets, laptops, and desktops */

}

@media (min-width:1281px) {
    /* hi-res laptops and desktops */

}

@media (min-width: 760px) {
    .footer-content {
        margin-left: auto;
        margin-right: auto;
        max-width: 1230px;
        padding: 40px 15px 50px;
        position: relative;
    }

    .footer-wave-svg {
        height: 50px;
    }

    .footer-content-column {
        width: 19.99%;
    }
}

@media (min-width: 568px) {
    /* .footer-content-column {
        width: 49.99%;
    } */
}


/* ==================== Whatsapp =================  */
.whatsapp_float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    /* font-size: 30px; */
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;

    transform: all .5s ease;
    background-color: #25d366;
    line-height: 50px;

}

.whatsapp_float::before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 50px;
    height: 50px;
    background-color: #25d366;
    border-radius: 50%;
    -webkit-animation: pulse-border 1500ms ease-out infinite;
    animation: pulse-border 1500ms ease-out infinite;
}

.whatsapp_float:focus {
    border: none;
    outline: none;
}

.whatsapp-icon {
    margin-top: 16px;
}

@keyframes pulse-border {
    0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }

    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 1;
    }
}
